<script setup>

const props = defineProps({
    loaded: Boolean,
    errorMsg: Object
});

</script>

<template>
    <div v-if="!loaded && !errorMsg" class="d-flex align-items-center justify-content-center">
        <div class="spinner-border spinner-border-sm mx-2" aria-hidden="true" />{{ $t('Loading') }}...
    </div>
    <div v-if="!loaded && errorMsg" class="d-flex align-items-center justify-content-center">
        <div class="text-danger" aria-hidden="true" />{{ $t('Error Loading') }}
    </div>
</template>